<template>
  <div id="TermsConditions">
    <b-container>
      <b-col>
        <b-row class="img-title">
          <b-col sm="4" md="2" class v-for="(item, index) in imageHeader" :key="index">
            <div class="header-img-list" :style="{'background-image':'url('+item.link+')'}"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="4" style="margin-bottom: 30px;">
            <span class="prizes">Terms & Conditions</span>
            <br />
            <span class="hastagfeel">#FeelMongolia</span>
          </b-col>
          <b-col sm="12" md="8" v-html="terms"></b-col>
        </b-row>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import img01 from "@/assets/img/campaign/img01.jpg";
import img02 from "@/assets/img/campaign/img02.jpg";
import img03 from "@/assets/img/campaign/img03.jpg";
import img04 from "@/assets/img/campaign/img04.jpg";
import img05 from "@/assets/img/campaign/img05.jpg";
import img06 from "@/assets/img/campaign/img06.jpg";
import metLogo from "@/assets/img/campaign/MET_logo_blue.png";
import axios from "axios";
export default {
  name: "partners",
  data() {
    return {
      imageHeader: [
        { link: img01 },
        { link: img02 },
        { link: img03 },
        { link: img04 },
        { link: img05 },
        { link: img06 }
      ],
      metLogo: metLogo,
      terms: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      const url = process.env.VUE_APP_API_URI;
      const query = {
        query: `query { campaign(id: "5d5380fe22c5ec0032d9fd93") {
                title name description content socialIdentifiers terms
                prizes { 
                  name description awardType awardOther criteria value images
                }
              }
            }`
      };
      axios
        .post(url, query, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY
          }
        })
        .then(res => {
          this.terms = res.data.data.campaign.terms;
        })
        .catch(console.error);
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

#TermsConditions {
  padding-top: 230px;

  .img-title {
    padding-bottom: 200px;
    .header-img-list {
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      padding-bottom: 100%;
    }
  }

  .prizes {
    font-weight: bold;
    font-size: 16px;
  }

  .hastagfeel {
    font-weight: bold;
    font-size: 25px;
  }

  @media (min-width: 1024px) {
    .prizes {
      font-weight: bold;
      font-size: 25px;
    }

    .hastagfeel {
      font-weight: bold;
      font-size: 36px;
    }
  }

  @media (min-width: 1200px) {
    .prizes {
      font-weight: bold;
      font-size: 25px;
    }

    .hastagfeel {
      font-weight: bold;
      font-size: 48px;
    }
  }
}
</style>


